<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0">Новый договор</p>
          <div>
            <el-button type="success" size="medium" >Сохранить</el-button>
            <el-button  size="medium" ><i class="fa-solid fa-xmark"></i></el-button>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5">
        <div class="timeline-items__right rounded-sm w-100 p-4">
          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2">Договор №</span>
            <el-input
              placeholder="Договор №"
              v-model="input"
              size="medium"
            ></el-input>
          </div>

          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2">Дата</span>
            <el-date-picker
              class="w-100"
              size="medium"
              v-model="value"
              type="date"
              placeholder="Дата рождения"
            ></el-date-picker>
          </div>

          <div class="app-form__group mb-4">
            <el-row :gutter="20">
              <el-col :span="22">
                <span class="input--label d-block mb-2">Статус</span>
                <el-select
                  class="w-100"
                  v-model="value"
                  placeholder="Статус"
                  size="medium"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="2">
                <div class="pulus mtl">
                  <i class="el-icon-plus"></i></div
              ></el-col>
            </el-row>
          </div>

          <div class="app-form__group mb-4">
            <el-row :gutter="20">
              <el-col :span="22">
                <span class="input--label d-block mb-2">Клиент</span>
                <el-select
                  class="w-100"
                  v-model="value"
                  placeholder="Клиент"
                  size="medium"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="2">
                <div class="pulus mtl">
                  <i class="el-icon-plus"></i>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="app-form__group mb-4">
            <el-row :gutter="20">
              <el-col :span="22">
                <span class="input--label d-block mb-2"
                  >Родительский договор</span
                >
                <el-select
                  class="w-100"
                  v-model="value"
                  placeholder="Родительский договор"
                  size="medium"
                >
                  <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-col>
              <el-col :span="2">
                <div class="pulus mtl">
                  <i class="el-icon-plus"></i>
                </div>
              </el-col>
            </el-row>
          </div>

          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2">Сумма</span>
            <el-input
              placeholder="Сумма"
              v-model="input"
              size="medium"
            ></el-input>
          </div>

          <div class="app-form__group mb-4">
            <span class="input--label d-block mb-2">Комментарий</span>
            <el-input
              type="textarea"
              size="medium"
              placeholder="Комментарий"
              v-model="input"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      input: "",
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      options2: [
        {
          value: "HTML",
          label: "HTML",
        },
        {
          value: "CSS",
          label: "CSS",
        },
        {
          value: "JavaScript",
          label: "JavaScript",
        },
      ],
      value: "",
      value2: [],
      value3: [],
    };
  },
  components: {},
  mounted() {},
};
</script>
